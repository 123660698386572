<script lang="ts">
import Vue from "vue";

// Types
import { Props } from "@/types";

// Blocks
import BlockPadding from "@/components/shared/blocks/padding.vue";

// Services
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";

export default Vue.extend({
  name: "BlockFaq",
  components: {
    BlockPadding,
  },
  props: {
    padding: {
      type: String,
      description: "The padding above the block",
      default: "10",
    } as Props.CGPropExtras<string>,
    faqQuestion: {
      type: String,
      description: "The frequently asked question to be displayed",
      default: "Your FAQ",
    } as Props.CGPropExtras<string>,
    faqAnswer: {
      type: String,
      description: "The answer",
      default: "Your answer",
    } as Props.CGPropExtras<string>,
    displayInACard: {
      type: Boolean,
      description: "If true, the faq will be displayed in a card",
      default: true,
    } as Props.CGPropExtras<boolean>,
    isHtmlAnswer: {
      type: Boolean,
      default: false,
    },
    useSlot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      infoModalService: new InfoModalService(this.$infoModal),
      faqShowing: false,
    };
  },
  computed: {},

  methods: {
    toggleFaq(): void {
      this.faqShowing = !this.faqShowing;
    },

    removeWhiteSpace(text: string): string {
      return text.replace(/\s/g, "");
    },
  },
  async mounted() {},
  watch: {},
});
</script>
<style scoped>
.underlineOnHover:hover {
  text-decoration: underline;
}
</style>
<template>
  <div>
    <BlockPadding :padding="padding" />

    <b-card v-if="displayInACard" class="p-0 m-0 shadow-none rounded-0">
      <h5 v-b-toggle="'collapse-' + removeWhiteSpace(faqQuestion)" class="underlineOnHover d-flex" @click="toggleFaq()">
        <span class="font-weight-semibold">{{ faqQuestion }}</span>
        <b-icon v-if="faqShowing" icon="chevron-up" class="ml-auto"></b-icon>
        <b-icon v-else icon="chevron-down" class="d-block ml-auto"></b-icon>
      </h5>
      <b-collapse :id="'collapse-' + removeWhiteSpace(faqQuestion)" class="mt-2">
        <template v-if="useSlot"><slot></slot></template>
        <template v-else>
          <div class="card-text" v-if="isHtmlAnswer" v-html="faqAnswer"></div>
          <span v-html="faqAnswer" v-else></span>
        </template>
      </b-collapse>
    </b-card>

    <template v-else>
      <h5 v-b-toggle="'collapse-' + removeWhiteSpace(faqQuestion)" class="underlineOnHover d-flex" @click="toggleFaq()">
        <span class="font-weight-semibold">{{ faqQuestion }}</span>
        <b-icon v-if="faqShowing" icon="chevron-up" class="ml-auto"></b-icon>
        <b-icon v-else icon="chevron-down" class="d-block ml-auto"></b-icon>
      </h5>
      <b-collapse :id="'collapse-' + removeWhiteSpace(faqQuestion)" class="mt-2">
        <div class="card-text" v-if="isHtmlAnswer" v-html="faqAnswer"></div>
        <span v-html="faqAnswer" v-else></span>
      </b-collapse>
    </template>
  </div>
</template>
