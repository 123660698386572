import { Props } from "@/types";
import { FinanceAgreementDataStore } from "./types";
import { Variable } from "@claimsgate/core-types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,

    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
    } as Props.CGPropExtras<string>,
    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: FinanceAgreementDataStore; type: string }> => [
        { name: "financeAgreement", type: "array of files" },
        { name: "deferFinanceAgreementUpload", type: "string" },
        { name: "letterOfAuthority", type: "array of files" },
        { name: "carFinanceFullName", type: "string" },
        { name: "carFinanceCreditorName", type: "string" },
        { name: "carFinanceBank", type: "string" },
        { name: "carFinanceGoodOrService", type: "string" },
        { name: "carFinanceAmountCredit", type: "string" },
        { name: "carFinanceAmountPayable", type: "string" },
        { name: "carFinanceInterest", type: "string" },
        { name: "carFinanceApr", type: "string" },
        { name: "carFinanceDateOfAgreement", type: "date" },
        { name: "carFinanceLengthOfAgreement", type: "string" },
        { name: "carFinanceMonthlyRepayment", type: "string" },
        { name: "carFinanceCommissionMention", type: "string" },
        { name: "carFinanceCommissionAmount", type: "string" },
        { name: "carFinanceCommissionTerms", type: "string" },
        { name: "carFinanceCommissionDisclosureStatus", type: "string" },
        { name: "carFinanceScanDate", type: "date" },
        { name: "carFinanceScanStatus", type: "string" },
        { name: "carFinanceScanVersion", type: "string" },
        { name: "carFinanceScanId", type: "string" },
        // .. to be populated
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: FinanceAgreementDataStore; type: string }>>,
    consentText: {
      type: String,
      required: false,
      default: "I give consent for the above DSAR to be made on my behalf",
      description: "The text to display for the consent",
    } as Props.Str,
  };
}
