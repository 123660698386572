<script lang="ts">
import Vue from "vue";
// Components
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDraw from "@/components/shared/blocks/draw.vue";
import BlockHtml from "@/components/shared/blocks/html.vue";
import { getUserId } from "@/helpers/vue";
import { getProps } from "./props";
import { getData } from "./data";
import { submit } from "./methods";
import { mappedComputed, computed } from "./computed";
import { Fragment } from "vue-frag";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
export default Vue.extend({
  name: "BlockDsar",
  components: {
    Fragment,
    BlockCheckbox,
    BlockPadding,
    BlockDraw,
    BlockHtml,
    BlockInvalidFeedback,
  },
  props: {
    ...getProps(),
  },
  methods: {
    async submit() {
      submit(this);
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  watch: {
    consentText() {
      this.BlockInputs.checkbox.text = this.consentText;
    },
  },
  computed: {
    ...mappedComputed(),
    loaHtml(): ReturnType<typeof computed.loaHtml> {
      return computed.loaHtml(this);
    },
    loaTemplateValues(): ReturnType<typeof computed.loaTemplateValues> {
      return computed.loaTemplateValues(this);
    },
    loaDisclaimerHtml(): ReturnType<typeof computed.loaDisclaimerHtml> {
      return computed.loaDisclaimerHtml(this);
    },
  },
  mounted() {
    const userId = getUserId();

    this.userId = userId;
    this.userDataService = new UserDataService(userId);
    this.user = this.userDataService.getCache();
  },
});
</script>
<style scoped></style>
<template>
  <Fragment>
    <div id="top"></div>
    <div v-if="view === 'consent'">
      <BlockPadding :padding="padding" />
      <BlockHtml v-bind="{ html: dsarConsentHtml }" />
      <BlockPadding :padding="padding" />
      <BlockCheckbox
        :answer.sync="BlockInputs.checkbox.answer"
        v-bind="BlockInputs.checkbox"
        v-bind.sync="BlockInputs.checkbox"
        :padding="padding"
        :text="consentText"
      />
      <BlockPadding :padding="padding" />
    </div>
    <div v-else-if="view === 'loa'">
      <BlockHtml v-bind="{ html: loaDisclaimerHtml }" />
      <BlockPadding :padding="padding" />
      <BlockHtml v-bind="{ html: loaHtml }" />
      <BlockPadding :padding="padding" />
      <BlockDraw
        v-bind="BlockInputs.draw"
        v-bind.sync="BlockInputs.draw"
        :invalidFeedback="'A signature is required'"
      />
      <BlockPadding :padding="padding" />
    </div>
    <template>
      <b-row class="no-gutters">
        <b-col class="text-center">
          <b-button
            @click="submit()"
            variant="primary"
            class="text-white"
            :size="theme.size"
            :squared="theme.squared"
            :block="false"
            style="margin-top: 10px"
          >
            <template v-if="uiToggles.isValidating">
              <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
            </template>
            <template v-else> Continue </template>
          </b-button>
          <BlockInvalidFeedback :invalidFeedback="pageError" :state="uiToggles.isPageError"></BlockInvalidFeedback>
        </b-col>
      </b-row>
    </template>
  </Fragment>
</template>
