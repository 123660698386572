import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import firebase from "firebase/compat/app";
import { getFirebaseBackend } from "@/authUtils";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { User } from "@claimsgate/core-types";
export enum DsarViews {
  consent = "consent",
  loa = "loa",
}

export function getData(state: Vue) {
  return {
    BlockInputs: {
      checkbox: UserVariableService.genBlockCheckboxProps({
        linkText: "",
        linkTarget: "",
        text: "",
        invalidFeedback: "You must provide consent to be able to continue.",
      }),
      ...UserVariableService.getBlocks(["draw"]),
    },
    uiToggles: {
      isValidating: false,
      isPageError: null,
    },
    user: {} as User,
    userDataService: undefined as UserDataService,
    claimId: state.$route.params.claimId as string,
    userId: undefined,
    funnelId: state.$route.params?.funnelId,
    db: getFirebaseBackend().firestore(),
    view: DsarViews.consent,
    dsarConsentHtml: `
    <h4><strong>DSAR Request Service: Information and Consent</strong></h4>

    <p>A Data Subject Access Request (DSAR) is a legal right that allows individuals to request access to their personal data held by organisations. By submitting a DSAR on your behalf, we aim to gain insight into the information that organisations have about you, ensuring transparency and control over your personal data.</p>

    <p>By signing below you authorize us to:</p>
    <ul>
      <li>Collect the necessary details from you, such as your full name, contact information, and any specific organisations or data categories you wish to target in the request.</li>
      <li>Draft a comprehensive and tailored DSAR letter, ensuring it meets all legal requirements and clearly states your request for access to your personal data.</li>
      <li>Submit the DSAR to the relevant organisations on your behalf, handling any correspondence or follow-up actions required.</li>
      <li>Once the organisations provide the requested information, we will review and analyze the data, identifying any inconsistencies, inaccuracies, or potential breaches of your data protection rights.</li>
      <li>Compile a detailed report outlining the information obtained from the DSAR, our findings, and any recommendations for further action, if necessary.</li>
    </ul>    
    `,
    pageError: "The page has encountered an unknown error.",
  };
}
