import { InitiateDsarTaskOptions } from "@claimsgate/core-types";
import { DsarViews } from "./data";
import { DsarInstance } from "./DsarInstance";

export async function submit(state: DsarInstance): Promise<void> {
  try {
    state.uiToggles.isPageError = null;

    if (state.view === DsarViews.consent) {
      if (!validateConsent(state)) return;

      state.view = DsarViews.loa;
      scrollTop();
      state.uiToggles.isValidating = false;
    } else if (state.view === DsarViews.loa) {
      if (!validateLoa(state)) return;

      await createInitiateDsarTask(state);

      await state.$store.dispatch("events/fire", { name: state.eventValues.next });

      state.uiToggles.isValidating = false;
    }
  } catch (exception) {
    console.error(exception);
    state.uiToggles.isPageError = false;
    state.uiToggles.isValidating = false;
  }
}
function scrollTop(): void {
  const topDiv = document.getElementById("top");
  const top = topDiv?.offsetTop;

  if (top) {
    window.scrollTo({
      top: top - 10,
      behavior: "smooth",
    });
  }
}

/**
 * Does the draw box have a signature
 */
function drawCompleted(state: DsarInstance): boolean {
  return !!state.BlockInputs.draw.answer;
}

/**
 * Has the check box been ticked
 */
export function checkAccepted(state: DsarInstance): boolean {
  return state.BlockInputs.checkbox.answer === "accepted";
}

function validateConsent(state: DsarInstance): boolean {
  state.uiToggles.isValidating = true;
  state.BlockInputs.checkbox.state = null;

  if (!checkAccepted(state)) {
    state.BlockInputs.checkbox.state = false;
    state.uiToggles.isValidating = false;
    return false;
  }

  state.BlockInputs.checkbox.state = true;
  return true;
}

function validateLoa(state: DsarInstance): boolean {
  state.uiToggles.isValidating = true;
  state.BlockInputs.draw.state = null;

  if (!drawCompleted(state)) {
    state.BlockInputs.draw.state = false;
    state.uiToggles.isValidating = false;
    return false;
  }

  state.BlockInputs.checkbox.state = true;
  return true;
}

export async function createInitiateDsarTask(state: DsarInstance): Promise<void> {
  // todays date plus 1 hour
  const date = new Date();
  date.setHours(date.getHours() + 1);
  const options: InitiateDsarTaskOptions = {
    userId: state.userId,
    claimId: state.claimId,
    custodianField: "financeLender",
    custodianMap: "financeLenderMap",
    documentStoreField: state.storeAs?.field ?? "financeAgreement",
    date,
    signature: state.BlockInputs.draw.answer,
  };
  const taskRef = state.db.collection("tasks").doc();

  await taskRef.set({
    performAt: date,
    status: "scheduled",
    worker: "initiateDsar",
    options: options,
  });
}
