import { getClaimDataService } from "@/helpers/vue";
import { BlockFinanceAgreementInstance } from "./BlockFinanceAgreement";

export async function mounted(state: BlockFinanceAgreementInstance) {
  state.userId = state.userService.getUserId();
  getClaimDataService<BlockFinanceAgreementInstance>(state);

  // clear state
  (state.BlockInputs.fileInput.answer as any) = ""; // may not be needed but the code before this was emitting an error in the block
  state.BlockInputs.fileInputButton.isProcessing = false;
  state.BlockInputs.fileInput.invalidFeedback = null;
  state.BlockInputs.financeAgreementCopyQuestion.answer = "";
}
