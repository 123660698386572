import { BlockFinanceAgreementInstance } from "./BlockFinanceAgreement";
import { ClaimsGateMotorSpecsGetVehicleResponse } from "@claimsgate/core-types";
import { ClaimsGateErrors, ClaimsGateFunctionReturnedError, getFunnelVariables, updateClaim } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils.js";
import { StorageService } from "@/helpers/ClaimsGate/StorageService";
import firebase from "firebase/compat/app";
import mime from "mime-types";

import omitBy from "lodash.omitby";
import isNil from "lodash.isnil";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

const MAX_COMBINED_FILE_SIZE = 36; // Mb

/** Methods to be consumed by Vue instance */
export const methods = {
  submitFinanceAgreements,
  submitDeferUpload,
};

/** Handles the logic when the user submits the view for confirming their keeper dates dates */
export async function submitFinanceAgreements(state: BlockFinanceAgreementInstance) {
  try {
    state.BlockInputs.fileInputButton.isProcessing = true;
    state.BlockInputs.fileInput.state = null;

    const files = state.BlockInputs.fileInput.answer.file as unknown as Array<File>;
    console.log(">>> files", files);

    // Check if user has uploaded at least one file
    if (!files || files.length === 0) {
      state.BlockInputs.fileInput.invalidFeedback = state.uiMessages.noFileUploaded;
      state.BlockInputs.fileInput.state = false;
      state.BlockInputs.fileInputButton.isProcessing = false;
      return;
    }

    // Check if the total size of all files is below 36Mb
    let combinedFileSize = 0;
    files.forEach((file) => {
      combinedFileSize += file.size / 1024 / 1024;
    });
    if (combinedFileSize > MAX_COMBINED_FILE_SIZE) {
      state.BlockInputs.fileInput.invalidFeedback = state.uiMessages.filesTooLarge;
    }

    await saveAgreementsOnClaim(state);

    // Call Finance Agreement Endpoint / API
    const response = await onCallGateway<"summariseCarFinanceAgreement">({
      functionName: "summariseCarFinanceAgreement",
      data: {
        claimId: state.claimId,
      },
    });

    // TODO: Jesse - handle response from summariseCarFinanceAgreement - any errors etc.
    if (response?.data?.error) {
      console.error("Error from summariseCarFinanceAgreement", response?.data?.error);
    }
    await state.$store.dispatch("events/fire", { name: state.eventValues.next });
    state.BlockInputs.fileInputButton.isProcessing = false;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

/** Returns the correct set of funnel variables */
async function _getFunnelVariables(state: BlockFinanceAgreementInstance) {
  const { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  return funnelVariables;
}

/** Saves all of the files on the claim */
export async function saveAgreementsOnClaim(state: BlockFinanceAgreementInstance): Promise<boolean> {
  const db = getFirebaseBackend().firestore();
  const value: { file: File[] } = state.BlockInputs.fileInput.answer as any;
  const filesStore = [];
  const userRef = db.collection("users").doc(state.userId);
  const storageService = new StorageService();
  await Promise.all(
    value.file.map(async (file) => {
      const userPath = `users/${state.userId}`;

      const extension = mime.extension(file.type);

      // Create a unique FileId for the file using Firestore
      const fileRef = userRef.collection("files").doc();
      const fileId = fileRef.id;
      const filePath = `${userPath}/${fileId}.${extension}`;

      // Upload the file to Firebase Storage
      await storageService.uploadFile(filePath, file);

      // Store metadata about the file
      const fileMeta = {
        name: file.name,
        mime: file.type,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        extension: extension,
        path: filePath,
        fileId: fileId,
        claimId: state.claimId,
      };

      console.log("File meta is: ", fileMeta);
      await fileRef.set(fileMeta, { merge: true });

      const fileStore = { type: "file", path: filePath, id: fileId };

      filesStore.push(fileStore);
    })
  );

  const funnelVariables = await _getFunnelVariables(state);
  const store = { financeAgreement: filesStore };
  const hashedFiles = await state.variablesService.hashData(store, funnelVariables);

  await updateClaim(db, state.userId, state.claimId, hashedFiles);

  return true;
}

/** Type guard to check if the response returned from getVehicleFromMotorSpecs is a ClaimsGateFunctionReturnedError */
function isClaimsGateFunctionReturnedError(
  response: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  console.log("isClaimsGateReturnedError", response, response as ClaimsGateFunctionReturnedError);
  return (response as ClaimsGateFunctionReturnedError)?.error?.id !== undefined;
}

// Create a generic try catch handler which will log the error and reset the view to the search vehicle view
function tryCatchHandler(state: BlockFinanceAgreementInstance, error: Error) {
  console.error(error);
}

async function submitDeferUpload(state: BlockFinanceAgreementInstance): Promise<boolean> {
  state.BlockInputs.fileInputButton.isProcessing = true;

  const funnelVariables = await _getFunnelVariables(state);

  const store = { deferFinanceAgreementUpload: "Yes" };

  const hashedStore = await state.variablesService.hashData(store, funnelVariables);

  const db = getFirebaseBackend().firestore();

  await updateClaim(db, state.userId, state.claimId, hashedStore);

  state.claimDataService.refresh();

  await state.$store.dispatch("events/fire", { name: state.eventValues.next });

  return true;
}
