<template>
  <div
    class="ui fluid search selection dropdown height-59"
    :class="{ 'active visible': showMenu, error: isError, disabled: isDisabled }"
    @click="openOptions"
    @focus="openOptions"
  >
    <!-- <i class="dropdown icon"></i> -->
    <input
      class="search form-control form-control-lg"
      :placeholder="placeholder"
      :class="[state === false ? 'is-invalid' : '', '', state === true ? 'is-valid' : '']"
      autocomplete="off"
      :tabindex="isDisabled ? -1 : 0"
      :id="id"
      :name="name"
      :value="searchText"
      @input="searchText = $event.target.value"
      ref="input"
      @focus.prevent="openOptions"
      @keyup.esc="closeOptions"
      @blur="blurInput"
      @keydown.up="prevItem"
      @keydown.down="nextItem"
      @keydown.enter.prevent=""
      @keyup.enter.prevent="enterItem"
      @keydown.delete="deleteTextOrItem"
    />
    <!-- <div class="text" :class="textClass" :data-vss-custom-attr="searchTextCustomAttr">{{ inputText }}</div> -->
    <div class="menu" ref="menu" @mousedown.prevent :class="menuClass" :style="menuStyle" tabindex="-1">
      <template v-for="(option, idx) in filteredOptions">
        <div
          :key="idx"
          class="item"
          :class="{ selected: option.selected || pointer === idx, disabled: option.disabled }"
          :data-vss-custom-attr="customAttrs[idx] ? customAttrs[idx] : ''"
          @click.stop="selectItem(option)"
          @mousedown="mousedownItem"
          @mouseenter="pointerSet(idx)"
        >
          {{ option.text }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import common from "./common";
import { baseMixin, commonMixin, optionAwareMixin } from "./mixins";

export default {
  name: "ModelSelect",
  mixins: [baseMixin, commonMixin, optionAwareMixin],
  props: {
    value: {
      type: [String, Number, Object, Boolean],
    },
    state: {
      type: Boolean,
      default: null,
    },
    fixedOptions: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Type to search",
    },
  },
  data() {
    return {
      showMenu: false,
      searchText: "",
      mousedownState: false, // mousedown on option menu
      pointer: -1,
    };
  },
  watch: {
    value(newValue) {
      this.pointer = this.filteredOptions.findIndex((option) => {
        return option.value === this.optionValue(newValue);
      });
    },
    searchText(newValue, old) {
      console.log("searchText", newValue, old, old.length > newValue.length || !newValue);
      if (newValue === "Other") {
        this.$emit("input", newValue);
        this.$emit("update:value", newValue);
        return;
      }
      if (old.length > newValue.length || !newValue) {
        this.$emit("input", null);
        this.$emit("update:value", null);
      }
    },
  },
  computed: {
    searchTextCustomAttr() {
      if (this.selectedOption && this.selectedOption.value) {
        return this.customAttr(this.selectedOption);
      }
      return "";
    },
    inputText() {
      if (this.searchText) {
        return "";
      } else {
        let text = this.placeholder;
        if (this.selectedOption) {
          text = this.selectedOption.text;
        }
        return text;
      }
    },
    customAttrs() {
      try {
        if (Array.isArray(this.options)) {
          return this.options.map((o) => this.customAttr(o));
        }
      } catch (e) {
        // if there is an error, just return an empty array
      }
      return [];
    },
    textClass() {
      if (!this.selectedOption && this.placeholder) {
        return "default";
      } else {
        return "";
      }
    },
    menuClass() {
      return {
        visible: this.showMenu,
        hidden: !this.showMenu,
      };
    },
    menuStyle() {
      return {
        display: this.showMenu ? "block" : "none",
      };
    },
    filteredOptions() {
      if (this.searchText) {
        return [
          ...this.options
            .filter((option) => {
              try {
                return this.filterPredicate(option.text, this.searchText);
              } catch (e) {
                return true;
              }
            })
            .slice(0, 30),
          ...this.fixedOptions,
        ];
      } else {
        return [...this.options.slice(0, 30), ...this.fixedOptions];
      }
    },
    selectedOption() {
      return this.options.find((option) => {
        return option.value === this.optionValue(this.value);
      });
    },
  },
  methods: {
    deleteTextOrItem() {
      console.log("deleteTextOrItem", this.value);
      if (this.value) {
        this.searchText = "";
        this.$emit("input", null);
      }
      if (!this.searchText && this.value) {
        this.selectItem({});
        this.openOptions();
      }
    },
    openOptions() {
      this.$emit("update:state", null);
      common.openOptions(this);
    },
    blurInput() {
      common.blurInput(this);
    },
    closeOptions() {
      common.closeOptions(this);
    },
    prevItem() {
      common.prevItem(this);
    },
    nextItem() {
      common.nextItem(this);
    },
    enterItem() {
      common.enterItem(this);
    },
    pointerSet(index) {
      common.pointerSet(this, index);
    },
    pointerAdjust() {
      common.pointerAdjust(this);
    },
    mousedownItem() {
      common.mousedownItem(this);
    },
    selectItem(option) {
      if (option.disabled) {
        return;
      }
      this.searchText = "";
      this.closeOptions();
      if (typeof this.value === "object" && this.value) {
        this.$emit("input", option);
        this.searchText = option;
      } else {
        this.$emit("update:value", option.value);
        this.$emit("input", option.value);
        if (option.value === option.text) {
          this.searchText = option.value;
        }
      }
      this.blurInput();
    },
    optionValue(value) {
      if (typeof value === "object" && value !== null) {
        return value.value;
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped>
.disabled {
  background-color: #f9fafb;
}
.height-59 {
  height: 59px !important;
}
.ui.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  text-align: left;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*******************************
            Content
*******************************/

/*--------------
      Menu
---------------*/

.ui.dropdown .menu {
  cursor: auto;
  position: absolute;
  display: none;
  outline: none;
  top: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  margin: 0em;
  padding: 0em 0em;
  background: #ffffff;
  font-size: 1em;
  text-shadow: none;
  text-align: left;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  z-index: 11;
  will-change: transform, opacity;
}
.ui.dropdown .menu > * {
  white-space: nowrap;
}

/*--------------
  Hidden Input
---------------*/

.ui.dropdown > input:not(.search):first-child,
.ui.dropdown > select {
  display: none !important;
}

/*--------------
 Dropdown Icon
---------------*/

.ui.dropdown > .dropdown.icon {
  position: relative;
  width: auto;
  font-size: 0.85714286em;
  margin: 0em 0em 0em 1em;
}
.ui.dropdown .menu > .item .dropdown.icon {
  width: auto;
  float: right;
  margin: 0em 0em 0em 1em;
}
.ui.dropdown .menu > .item .dropdown.icon + .text {
  margin-right: 1em;
}

/*--------------
      Text
---------------*/

.ui.dropdown > .text {
  display: inline-block;
  -webkit-transition: none;
  transition: none;
}

/*--------------
    Menu Item
---------------*/

.ui.dropdown .menu > .item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1.8em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
}
.ui.dropdown .menu > .item:first-child {
  border-top-width: 0px;
}

/*--------------
  Floated Content
---------------*/

.ui.dropdown > .text > [class*="right floated"],
.ui.dropdown .menu .item > [class*="right floated"] {
  float: right !important;
  margin-right: 0em !important;
  margin-left: 1em !important;
}
.ui.dropdown > .text > [class*="left floated"],
.ui.dropdown .menu .item > [class*="left floated"] {
  float: left !important;
  margin-left: 0em !important;
  margin-right: 1em !important;
}
.ui.dropdown .menu .item > .icon.floated,
.ui.dropdown .menu .item > .flag.floated,
.ui.dropdown .menu .item > .image.floated,
.ui.dropdown .menu .item > img.floated {
  margin-top: 0em;
}

/*--------------
  Menu Divider
---------------*/

.ui.dropdown .menu > .header {
  margin: 1rem 0rem 0.75rem;
  padding: 0em 1.14285714rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.78571429em;
  font-weight: bold;
  text-transform: uppercase;
}
.ui.dropdown .menu > .divider {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  height: 0em;
  margin: 0.5em 0em;
}
.ui.dropdown.dropdown .menu > .input {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1.14285714rem 0.78571429rem;
  min-width: 10rem;
}
.ui.dropdown .menu > .header + .input {
  margin-top: 0em;
}
.ui.dropdown .menu > .input:not(.transparent) input {
  padding: 0.5em 1em;
}
.ui.dropdown .menu > .input:not(.transparent) .button,
.ui.dropdown .menu > .input:not(.transparent) .icon,
.ui.dropdown .menu > .input:not(.transparent) .label {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/*-----------------
  Item Description
-------------------*/

.ui.dropdown > .text > .description,
.ui.dropdown .menu > .item > .description {
  float: right;
  margin: 0em 0em 0em 1em;
  color: rgba(0, 0, 0, 0.4);
}

/*-----------------
       Message
-------------------*/

.ui.dropdown .menu > .message {
  padding: 0.78571429rem 1.14285714rem;
  font-weight: normal;
}
.ui.dropdown .menu > .message:not(.ui) {
  color: rgba(0, 0, 0, 0.4);
}

/*--------------
    Sub Menu
---------------*/

.ui.dropdown .menu .menu {
  top: 0% !important;
  left: 100%;
  right: auto;
  margin: 0em 0em 0em -0.5em !important;
  border-radius: 0.28571429rem !important;
  z-index: 21 !important;
}

/* Hide Arrow */
.ui.dropdown .menu .menu:after {
  display: none;
}

/*--------------
   Sub Elements
---------------*/

/* Icons / Flags / Labels / Image */
.ui.dropdown > .text > .icon,
.ui.dropdown > .text > .label,
.ui.dropdown > .text > .flag,
.ui.dropdown > .text > img,
.ui.dropdown > .text > .image {
  margin-top: 0em;
}
.ui.dropdown .menu > .item > .icon,
.ui.dropdown .menu > .item > .label,
.ui.dropdown .menu > .item > .flag,
.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img {
  margin-top: 0em;
}
.ui.dropdown > .text > .icon,
.ui.dropdown > .text > .label,
.ui.dropdown > .text > .flag,
.ui.dropdown > .text > img,
.ui.dropdown > .text > .image,
.ui.dropdown .menu > .item > .icon,
.ui.dropdown .menu > .item > .label,
.ui.dropdown .menu > .item > .flag,
.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img {
  margin-left: 0em;
  float: none;
  margin-right: 0.78571429rem;
}

/*--------------
     Image
---------------*/

.ui.dropdown > .text > img,
.ui.dropdown > .text > .image,
.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img {
  display: inline-block;
  vertical-align: top;
  width: auto;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  max-height: 2em;
}

/*******************************
            Coupling
*******************************/

/*--------------
      Menu
---------------*/

/* Remove Menu Item Divider */
.ui.dropdown .ui.menu > .item:before,
.ui.menu .ui.dropdown .menu > .item:before {
  display: none;
}

/* Prevent Menu Item Border */
.ui.menu .ui.dropdown .menu .active.item {
  border-left: none;
}

/* Automatically float dropdown menu right on last menu item */
.ui.menu .right.menu .dropdown:last-child .menu,
.ui.menu .right.dropdown.item .menu,
.ui.buttons > .ui.dropdown:last-child .menu {
  left: auto;
  right: 0em;
}

/*--------------
      Label
---------------*/

/* Dropdown Menu */
.ui.label.dropdown .menu {
  min-width: 100%;
}

/*--------------
     Button
---------------*/

/* No Margin On Icon Button */
.ui.dropdown.icon.button > .dropdown.icon {
  margin: 0em;
}
.ui.button.dropdown .menu {
  min-width: 100%;
}

/*******************************
              Types
*******************************/

/*--------------
    Selection
---------------*/

/* Displays like a select box */
.ui.selection.dropdown {
  cursor: pointer;
  word-wrap: break-word;
  /* line-height: 1em; */
  white-space: normal;
  outline: 0;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  /* min-width: 14em; */
  /* min-height: 2.71428571em; */
  /* background: #ffffff; */
  display: inline-block;
  /* padding: 0.78571429em 2.1em 0.78571429em 1em; */
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
  box-shadow: none;
  /* border: 1px solid rgba(34, 36, 38, 0.15); */
  /* border-radius: 0.28571429rem; */
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
}
.ui.selection.dropdown.visible,
.ui.selection.dropdown.active {
  z-index: 10;
}
select.ui.dropdown {
  height: 38px;
  padding: 0.5em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  visibility: visible;
}
.ui.selection.dropdown > .search.icon,
.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  right: 1em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.91666667em;
  opacity: 0.8;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}

/* Compact */
.ui.compact.selection.dropdown {
  min-width: 0px;
}

/*  Selection Menu */
.ui.selection.dropdown .menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  border-top-width: 0px !important;
  width: auto;
  outline: none;
  margin: 0px -1px;
  min-width: calc(100% + 2px);
  width: calc(100% + 2px);
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.ui.selection.dropdown .menu:after,
.ui.selection.dropdown .menu:before {
  display: none;
}

/*--------------
    Message
---------------*/

.ui.selection.dropdown .menu > .message {
  padding: 0.78571429rem 1.14285714rem;
}
@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    max-height: 20.01428571rem;
  }
}
@media only screen and (min-width: 768px) {
  .ui.selection.dropdown .menu {
    max-height: 17.68571429rem;
  }
}
@media only screen and (min-width: 992px) {
  .ui.selection.dropdown .menu {
    max-height: 19.02857143rem;
  }
}
@media only screen and (min-width: 1920px) {
  .ui.selection.dropdown .menu {
    max-height: 23.37142857rem;
  }
}

/* Menu Item */
.ui.selection.dropdown .menu > .item {
  border-top: 1px solid #fafafa;
  padding: 0.78571429rem 1.14285714rem !important;
  white-space: normal;
  word-wrap: normal;
}

/* User Item */
.ui.selection.dropdown .menu > .hidden.addition.item {
  display: none;
}

/* Hover */
.ui.selection.dropdown:hover {
  border-color: rgba(34, 36, 38, 0.35);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Active */
.ui.selection.active.dropdown {
  border-color: #96c8da;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}
.ui.selection.active.dropdown .menu {
  border-color: #96c8da;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Focus */
.ui.selection.dropdown:focus {
  border-color: #96c8da;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui.selection.dropdown:focus .menu {
  border-color: #96c8da;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Visible */
.ui.selection.visible.dropdown > .text:not(.default) {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
}

/* Visible Hover */
.ui.selection.active.dropdown:hover {
  border-color: #96c8da;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}
.ui.selection.active.dropdown:hover .menu {
  border-color: #96c8da;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Dropdown Icon */
.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
  opacity: "";
  z-index: 3;
}

/* Connecting Border */
.ui.active.selection.dropdown {
  border-bottom-left-radius: 0em !important;
  border-bottom-right-radius: 0em !important;
}

/* Empty Connecting Border */
.ui.active.empty.selection.dropdown {
  border-radius: 0.28571429rem !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ui.active.empty.selection.dropdown .menu {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*--------------
   Searchable
---------------*/

/*--------------
    Multiple
---------------*/

/* Multiple Selection */
.ui.multiple.dropdown {
  padding: 0.22619048em 2.1em 0.22619048em 0.35714286em;
}
.ui.multiple.dropdown .menu {
  cursor: auto;
}

/* Multiple Search Selection */
.ui.multiple.search.dropdown,
.ui.multiple.search.dropdown > input.search {
  cursor: text;
}

/* Selection Label */
.ui.multiple.dropdown > .label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 1em;
  padding: 0.35714286em 0.78571429em;
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Dropdown Icon */
.ui.multiple.dropdown .dropdown.icon {
  margin: "";
  padding: "";
}

/* Text */
.ui.multiple.dropdown > .text {
  position: static;
  padding: 0;
  max-width: 100%;
  margin: 0.45238095em 0em 0.45238095em 0.64285714em;
  line-height: 1.21428571em;
}
.ui.multiple.dropdown > .label ~ input.search {
  margin-left: 0.14285714em !important;
}
.ui.multiple.dropdown > .label ~ .text {
  display: none;
}

/*-----------------
  Multiple Search
-----------------*/

/* Prompt Text */
.ui.multiple.search.dropdown > .text {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: inherit;
  margin: 0.45238095em 0em 0.45238095em 0.64285714em;
  line-height: 1.21428571em;
}
.ui.multiple.search.dropdown > .label ~ .text {
  display: none;
}

/* Search */
.ui.multiple.search.dropdown > input.search {
  position: static;
  padding: 0;
  max-width: 100%;
  margin: 0.45238095em 0em 0.45238095em 0.64285714em;
  width: 2.2em;
  line-height: 1.21428571em;
}

/*--------------
     Inline
---------------*/

.ui.inline.dropdown {
  cursor: pointer;
  display: inline-block;
  color: inherit;
}
.ui.inline.dropdown .dropdown.icon {
  margin: 0em 0.21428571em 0em 0.21428571em;
  vertical-align: baseline;
}
.ui.inline.dropdown > .text {
  font-weight: bold;
}
.ui.inline.dropdown .menu {
  cursor: auto;
  margin-top: 0.21428571em;
  border-radius: 0.28571429rem;
}

/*******************************
            States
*******************************/

/*--------------------
        Active
----------------------*/

/* Menu Item Active */
.ui.dropdown .menu .active.item {
  background: transparent;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.95);
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 12;
}

/*--------------------
        Hover
----------------------*/

/* Menu Item Hover */
.ui.dropdown .menu > .item:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  z-index: 13;
}

/*--------------------
       Loading
---------------------*/

.ui.loading.dropdown > i.icon {
  height: 1em !important;
}
.ui.loading.selection.dropdown > i.icon {
  padding: 1.5em 1.28571429em !important;
}
.ui.loading.dropdown > i.icon:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}
.ui.loading.dropdown > i.icon:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0px 0px 0px 1px transparent;
  box-shadow: 0px 0px 0px 1px transparent;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: dropdown-spin 0.6s linear;
  animation: dropdown-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: 0.2em;
}

/* Coupling */
.ui.loading.dropdown.button > i.icon:before,
.ui.loading.dropdown.button > i.icon:after {
  display: none;
}
@-webkit-keyframes dropdown-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dropdown-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*--------------------
     Default Text
----------------------*/

.ui.dropdown:not(.button) > .default.text,
.ui.default.dropdown:not(.button) > .text {
  color: rgba(191, 191, 191, 0.87);
}
.ui.dropdown:not(.button) > input:focus ~ .default.text,
.ui.default.dropdown:not(.button) > input:focus ~ .text {
  color: rgba(115, 115, 115, 0.87);
}

/*--------------------
        Loading
----------------------*/

.ui.loading.dropdown > .text {
  -webkit-transition: none;
  transition: none;
}

/* Used To Check Position */
.ui.dropdown .loading.menu {
  display: block;
  visibility: hidden;
  z-index: -1;
}
.ui.dropdown > .loading.menu {
  left: 0px !important;
  right: auto !important;
}
.ui.dropdown > .menu .loading.menu {
  left: 100% !important;
  right: auto !important;
}

/*--------------------
    Keyboard Select
----------------------*/

/* Selected Item */
.ui.dropdown.selected,
.ui.dropdown .menu .selected.item {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------------
    Search Filtered
----------------------*/

/* Filtered Item */
.ui.dropdown > .filtered.text {
  visibility: hidden;
}
.ui.dropdown .filtered.item {
  display: none !important;
}

/*--------------------
        Error
----------------------*/

.ui.dropdown.error,
.ui.dropdown.error > .text,
.ui.dropdown.error > .default.text {
  color: #9f3a38;
}
.ui.selection.dropdown.error {
  background: #fff6f6;
  border-color: #e0b4b4;
}
.ui.selection.dropdown.error:hover {
  border-color: #e0b4b4;
}
.ui.dropdown.error > .menu,
.ui.dropdown.error > .menu .menu {
  border-color: #e0b4b4;
}
.ui.dropdown.error > .menu > .item {
  color: #9f3a38;
}
.ui.multiple.selection.error.dropdown > .label {
  border-color: #e0b4b4;
}

/* Item Hover */
.ui.dropdown.error > .menu > .item:hover {
  background-color: #fff2f2;
}

/* Item Active */
.ui.dropdown.error > .menu .active.item {
  background-color: #fdcfcf;
}

/*--------------------
        Clear
----------------------*/

.ui.dropdown > .clear.dropdown.icon {
  opacity: 0.8;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.ui.dropdown > .clear.dropdown.icon:hover {
  opacity: 1;
}

/*--------------------
        Disabled
----------------------*/

/* Disabled */
.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  cursor: default;
  pointer-events: none;
  opacity: 0.45;
}

/*******************************
           Variations
*******************************/

/*--------------
    Direction
---------------*/

/* Flyout Direction */
.ui.dropdown .menu {
  left: 0px;
}

/* Default Side (Right) */
.ui.dropdown .right.menu > .menu,
.ui.dropdown .menu .right.menu {
  left: 100% !important;
  right: auto !important;
  border-radius: 0.28571429rem !important;
}

/* Leftward Opening Menu */
.ui.dropdown > .left.menu {
  left: auto !important;
  right: 0px !important;
}
.ui.dropdown > .left.menu .menu,
.ui.dropdown .menu .left.menu {
  left: auto;
  right: 100%;
  margin: 0em -0.5em 0em 0em !important;
  border-radius: 0.28571429rem !important;
}
.ui.dropdown .item .left.dropdown.icon,
.ui.dropdown .left.menu .item .dropdown.icon {
  width: auto;
  float: left;
  margin: 0em 0em 0em 0em;
}
.ui.dropdown .item .left.dropdown.icon,
.ui.dropdown .left.menu .item .dropdown.icon {
  width: auto;
  float: left;
  margin: 0em 0em 0em 0em;
}
.ui.dropdown .item .left.dropdown.icon + .text,
.ui.dropdown .left.menu .item .dropdown.icon + .text {
  margin-left: 1em;
  margin-right: 0em;
}

/*--------------
     Upward
---------------*/

/* Upward Main Menu */
.ui.upward.dropdown > .menu {
  top: auto;
  bottom: 100%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

/* Upward Sub Menu */
.ui.dropdown .upward.menu {
  top: auto !important;
  bottom: 0 !important;
}

/* Active Upward */
.ui.simple.upward.active.dropdown,
.ui.simple.upward.dropdown:hover {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em !important;
}
.ui.upward.dropdown.button:not(.pointing):not(.floating).active {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

/* Selection */
.ui.upward.selection.dropdown .menu {
  border-top-width: 1px !important;
  border-bottom-width: 0px !important;
  -webkit-box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
}
.ui.upward.selection.dropdown:hover {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
}

/* Active Upward */
.ui.active.upward.selection.dropdown {
  border-radius: 0em 0em 0.28571429rem 0.28571429rem !important;
}

/* Visible Upward */
.ui.upward.selection.dropdown.visible {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0em 0em 0.28571429rem 0.28571429rem !important;
}

/* Visible Hover Upward */
.ui.upward.active.selection.dropdown:hover {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
}
.ui.upward.active.selection.dropdown:hover .menu {
  -webkit-box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
}

/*--------------
     Simple
---------------*/

/*  Selection Menu */
.ui.scrolling.dropdown .menu,
.ui.dropdown .scrolling.menu {
  overflow-x: hidden;
  overflow-y: auto;
}
.ui.scrolling.dropdown .menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  min-width: 100% !important;
  width: auto !important;
}
.ui.dropdown .scrolling.menu {
  position: static;
  overflow-y: auto;
  border: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  min-width: 100% !important;
  width: auto !important;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.ui.scrolling.dropdown .menu .item.item.item,
.ui.dropdown .scrolling.menu > .item.item.item {
  border-top: none;
}
.ui.scrolling.dropdown .menu .item:first-child,
.ui.dropdown .scrolling.menu .item:first-child {
  border-top: none;
}
.ui.dropdown > .animating.menu .scrolling.menu,
.ui.dropdown > .visible.menu .scrolling.menu {
  display: block;
}

/* Scrollbar in IE */
@media all and (-ms-high-contrast: none) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    min-width: calc(100% - 17px);
  }
}
@media only screen and (max-width: 767px) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: 12.28571429rem;
  }
}
@media only screen and (min-width: 768px) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: 17.42857143rem;
  }
}
@media only screen and (min-width: 992px) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: 22.57142857rem;
  }
}
@media only screen and (min-width: 1920px) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: 22.57142857rem;
  }
}

/*--------------
     Simple
---------------*/

/* Displays without javascript */
.ui.simple.dropdown .menu:before,
.ui.simple.dropdown .menu:after {
  display: none;
}
.ui.simple.dropdown .menu {
  position: absolute;
  display: block;
  overflow: hidden;
  top: -9999px !important;
  opacity: 0;
  width: 0;
  height: 0;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.ui.simple.active.dropdown,
.ui.simple.dropdown:hover {
  border-bottom-left-radius: 0em !important;
  border-bottom-right-radius: 0em !important;
}
.ui.simple.active.dropdown > .menu,
.ui.simple.dropdown:hover > .menu {
  overflow: visible;
  width: auto;
  height: auto;
  top: 100% !important;
  opacity: 1;
}
.ui.simple.dropdown > .menu > .item:active > .menu,
.ui.simple.dropdown:hover > .menu > .item:hover > .menu {
  overflow: visible;
  width: auto;
  height: auto;
  top: 0% !important;
  left: 100% !important;
  opacity: 1;
}
.ui.simple.disabled.dropdown:hover .menu {
  display: none;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

/* Visible */
.ui.simple.visible.dropdown > .menu {
  display: block;
}

/*--------------
      Fluid
---------------*/

.ui.fluid.dropdown {
  display: block;
  width: 100%;
  min-width: 0em;
}
.ui.fluid.dropdown > .dropdown.icon {
  float: right;
}

/*--------------
    Floating
---------------*/

.ui.floating.dropdown .menu {
  left: 0;
  right: auto;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15) !important;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.28571429rem !important;
}
.ui.floating.dropdown > .menu {
  margin-top: 0.5em !important;
  border-radius: 0.28571429rem !important;
}

/*--------------
     Pointing
---------------*/

.ui.pointing.dropdown > .menu {
  top: 100%;
  margin-top: 0.78571429rem;
  border-radius: 0.28571429rem;
}
.ui.pointing.dropdown > .menu:after {
  display: block;
  position: absolute;
  pointer-events: none;
  content: "";
  visibility: visible;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 0.5em;
  height: 0.5em;
  -webkit-box-shadow: -1px -1px 0px 0px rgba(34, 36, 38, 0.15);
  box-shadow: -1px -1px 0px 0px rgba(34, 36, 38, 0.15);
  background: #ffffff;
  z-index: 2;
}
.ui.pointing.dropdown > .menu:after {
  top: -0.25em;
  left: 50%;
  margin: 0em 0em 0em -0.25em;
}

/* Top Left Pointing */
.ui.top.left.pointing.dropdown > .menu {
  top: 100%;
  bottom: auto;
  left: 0%;
  right: auto;
  margin: 1em 0em 0em;
}
.ui.top.left.pointing.dropdown > .menu {
  top: 100%;
  bottom: auto;
  left: 0%;
  right: auto;
  margin: 1em 0em 0em;
}
.ui.top.left.pointing.dropdown > .menu:after {
  top: -0.25em;
  left: 1em;
  right: auto;
  margin: 0em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Top Right Pointing */
.ui.top.right.pointing.dropdown > .menu {
  top: 100%;
  bottom: auto;
  right: 0%;
  left: auto;
  margin: 1em 0em 0em;
}
.ui.top.pointing.dropdown > .left.menu:after,
.ui.top.right.pointing.dropdown > .menu:after {
  top: -0.25em;
  left: auto !important;
  right: 1em !important;
  margin: 0em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Left Pointing */
.ui.left.pointing.dropdown > .menu {
  top: 0%;
  left: 100%;
  right: auto;
  margin: 0em 0em 0em 1em;
}
.ui.left.pointing.dropdown > .menu:after {
  top: 1em;
  left: -0.25em;
  margin: 0em 0em 0em 0em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ui.left:not(.top):not(.bottom).pointing.dropdown > .left.menu {
  left: auto !important;
  right: 100% !important;
  margin: 0em 1em 0em 0em;
}
.ui.left:not(.top):not(.bottom).pointing.dropdown > .left.menu:after {
  top: 1em;
  left: auto;
  right: -0.25em;
  margin: 0em 0em 0em 0em;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* Right Pointing */
.ui.right.pointing.dropdown > .menu {
  top: 0%;
  left: auto;
  right: 100%;
  margin: 0em 1em 0em 0em;
}
.ui.right.pointing.dropdown > .menu:after {
  top: 1em;
  left: auto;
  right: -0.25em;
  margin: 0em 0em 0em 0em;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* Bottom Pointing */
.ui.bottom.pointing.dropdown > .menu {
  top: auto;
  bottom: 100%;
  left: 0%;
  right: auto;
  margin: 0em 0em 1em;
}
.ui.bottom.pointing.dropdown > .menu:after {
  top: auto;
  bottom: -0.25em;
  right: auto;
  margin: 0em;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Reverse Sub-Menu Direction */
.ui.bottom.pointing.dropdown > .menu .menu {
  top: auto !important;
  bottom: 0px !important;
}

/* Bottom Left */
.ui.bottom.left.pointing.dropdown > .menu {
  left: 0%;
  right: auto;
}
.ui.bottom.left.pointing.dropdown > .menu:after {
  left: 1em;
  right: auto;
}

/* Bottom Right */
.ui.bottom.right.pointing.dropdown > .menu {
  right: 0%;
  left: auto;
}
.ui.bottom.right.pointing.dropdown > .menu:after {
  left: auto;
  right: 1em;
}

/* Upward pointing */
.ui.pointing.upward.dropdown .menu,
.ui.top.pointing.upward.dropdown .menu {
  top: auto !important;
  bottom: 100% !important;
  margin: 0em 0em 0.78571429rem;
  border-radius: 0.28571429rem;
}
.ui.pointing.upward.dropdown .menu:after,
.ui.top.pointing.upward.dropdown .menu:after {
  top: 100% !important;
  bottom: auto !important;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 1px 1px 0px 0px rgba(34, 36, 38, 0.15);
  margin: -0.25em 0em 0em;
}

/* Right Pointing Upward */
.ui.right.pointing.upward.dropdown:not(.top):not(.bottom) .menu {
  top: auto !important;
  bottom: 0 !important;
  margin: 0em 1em 0em 0em;
}
.ui.right.pointing.upward.dropdown:not(.top):not(.bottom) .menu:after {
  top: auto !important;
  bottom: 0 !important;
  margin: 0em 0em 1em 0em;
  -webkit-box-shadow: -1px -1px 0px 0px rgba(34, 36, 38, 0.15);
  box-shadow: -1px -1px 0px 0px rgba(34, 36, 38, 0.15);
}

/* Left Pointing Upward */
.ui.left.pointing.upward.dropdown:not(.top):not(.bottom) .menu {
  top: auto !important;
  bottom: 0 !important;
  margin: 0em 0em 0em 1em;
}
.ui.left.pointing.upward.dropdown:not(.top):not(.bottom) .menu:after {
  top: auto !important;
  bottom: 0 !important;
  margin: 0em 0em 1em 0em;
  -webkit-box-shadow: -1px -1px 0px 0px rgba(34, 36, 38, 0.15);
  box-shadow: -1px -1px 0px 0px rgba(34, 36, 38, 0.15);
}

/*******************************
         Theme Overrides
*******************************/

/* Dropdown Carets */
.ui.search.selcetion {
  height: 78px !important;
}
.ui.dropdown > .dropdown.icon {
  font-family: "Dropdown";
  line-height: 1;
  height: 1em;
  width: 1.23em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}
.ui.dropdown > .dropdown.icon {
  width: auto;
}
.ui.dropdown > .dropdown.icon:before {
  content: "\f0d7";
}

/* Sub Menu */
.ui.dropdown .menu .item .dropdown.icon:before {
  content: "\f0da" /*rtl:'\f0d9'*/;
}
.ui.dropdown .item .left.dropdown.icon:before,
.ui.dropdown .left.menu .item .dropdown.icon:before {
  content: "\f0d9" /*rtl:"\f0da"*/;
}

/* Vertical Menu Dropdown */
.ui.vertical.menu .dropdown.item > .dropdown.icon:before {
  content: "\f0da" /*rtl:"\f0d9"*/;
}
.ui.dropdown > .clear.icon:before {
  content: "\f00d";
}
/* Icons for Reference (Subsetted in 2.4.0)
  .dropdown.down:before { content: "\f0d7"; }
  .dropdown.up:before { content: "\f0d8"; }
  .dropdown.left:before { content: "\f0d9"; }
  .dropdown.right:before { content: "\f0da"; }
  .dropdown.close:before { content: "\f00d"; }
*/

/*******************************
        User Overrides
*******************************/
</style>
