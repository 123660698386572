import { Variable } from "@claimsgate/core-types";
import { Props } from "@/types";
export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.Str,
    consentText: {
      type: String,
      required: false,
      default: "I give consent for the above DSAR to be made on my behalf",
      description: "The text to display for the consent",
    } as Props.Str,
    storeAs: {
      required: false,
    } as Props.VueProps<Pick<Variable, "field">>,
  };
}
